import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { TechniquesOverviewFragment, TechniquesPageFragment, TechniquesPageQuery } from '../entities/operationResults';
import Meta from './atoms/Meta';
import OverviewContainer from './organisms/OverviewContainer';
import { removeTags } from '../utils/removeTags';

export const query = graphql`
    query TechniquesPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...TechniquesPageFragment
            }
            entries(site: $site, type: Technique) {
                ...TechniquesOverviewFragment
            }
        }
    }

    fragment TechniquesOverviewFragment on Craft_Technique {
        __typename
        title
        uri
        slug
        id
        tint {
            hex
        }
    }
`;

export const fragment = graphql`
    fragment TechniquesPageFragment on Craft_Techniques {
        title
        advancedDescription {
            content
        }
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    in?: boolean;
    data: TechniquesPageQuery;
}

const Techniques: FC<Props> = ({ data, ...props }) => {
    const techniques =
        data.craft.entries?.filter((technique): technique is TechniquesOverviewFragment => !!technique) ?? [];
    const entry = data.craft.entry as TechniquesPageFragment;
    const { advancedDescription, seo, title } = entry;

    const meta = seo && seo[0];
    const description = advancedDescription && advancedDescription.content;
    const metaDescription = description && removeTags(description);

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : metaDescription}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <OverviewContainer
                description={description || undefined}
                in={props.in}
                data={techniques}
                advancedDescription
            />
        </>
    );
};

export default Techniques;
